import "./ShareButton.css";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ShareIcon from '@mui/icons-material/Share';
import {
    EmailShareButton,
    FacebookShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton,
    TelegramShareButton,
    TumblrShareButton,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    FacebookMessengerIcon,
    GabIcon,
    HatenaIcon,
    InstapaperIcon,
    LineIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon,
    XIcon, FacebookMessengerShareButton,
} from "react-share";

const ReactSwal = withReactContent(Swal)

function ShareButton() {
    return (
        <div
            className="button-3 share-button"
            onClick={() => {
                ReactSwal.fire({
                    title: "Share",
                    html: <div className="platform-share-buttons-container">
                        <WhatsappShareButton url={window.location.href} children={<div className="platform-share-button"><WhatsappIcon /> Whatsapp</div>} />
                        <LinkedinShareButton url={window.location.href} children={<div className="platform-share-button"><LinkedinIcon /> Linkedin</div>} />
                        <FacebookShareButton url={window.location.href} children={<div className="platform-share-button"><FacebookIcon /> Facebook</div>} />
                        <FacebookMessengerShareButton appId="" url={window.location.href} children={<div className="platform-share-button"><FacebookMessengerIcon /> Messenger</div>} />
                        <EmailShareButton url={window.location.href} children={<div className="platform-share-button"><EmailIcon /> Email</div>} />
                        <TwitterShareButton url={window.location.href} children={<div className="platform-share-button"><XIcon /> X</div>} />
                        <PinterestShareButton media="https://www.picky.careers/favicon.png" url={window.location.href} children={<div className="platform-share-button"><PinterestIcon /> Pinterest</div>} />
                        <PocketShareButton url={window.location.href} children={<div className="platform-share-button"><PocketIcon /> Pocket</div>} />
                        <RedditShareButton url={window.location.href} children={<div className="platform-share-button"><RedditIcon /> Reddit</div>} />
                        <TelegramShareButton url={window.location.href} children={<div className="platform-share-button"><TelegramIcon /> Telegram</div>} />
                        <TumblrShareButton url={window.location.href} children={<div className="platform-share-button"><TumblrIcon /> Tumblr</div>} />
                        <HatenaShareButton url={window.location.href} children={<div className="platform-share-button"><HatenaIcon /> Hatena</div>} />
                        <InstapaperShareButton url={window.location.href} children={<div className="platform-share-button"><InstapaperIcon /> Instapaper</div>} />
                        <LivejournalShareButton url={window.location.href} children={<div className="platform-share-button"><LivejournalIcon /> Livejournal</div>} />
                        <MailruShareButton url={window.location.href} children={<div className="platform-share-button"><MailruIcon /> Mailru</div>} />
                        <OKShareButton url={window.location.href} children={<div className="platform-share-button"><OKIcon /> Ok</div>} />
                        <ViberShareButton url={window.location.href} children={<div className="platform-share-button"><ViberIcon /> Viber</div>} />
                        <VKShareButton url={window.location.href} children={<div className="platform-share-button"><VKIcon /> VK</div>} />
                        <WorkplaceShareButton url={window.location.href} children={<div className="platform-share-button"><WorkplaceIcon /> Workplace</div>} />
                    </div>,
                    showCloseButton: true,
                    showConfirmButton: false,
                })
            }}
        >
            <ShareIcon />
            <div>Share</div>
        </div>
    );
}

export default ShareButton;
